.knockout-autocomplete.menu {
    background-color: white;
    padding: 3px 0;
}

.knockout-autocomplete.floating-menu {
    position: fixed;
    border: thin #aaa solid;
    margin: 0;
    margin-top: 3px;
    -webkit-box-shadow: 1px 1px 4px rgba(50, 50, 50, 0.75);
    -moz-box-shadow:    1px 1px 4px rgba(50, 50, 50, 0.75);
    box-shadow:         1px 1px 4px rgba(50, 50, 50, 0.75);
}

.knockout-autocomplete.after-menu {
    position: absolute;
}

.knockout-autocomplete.menu li {
    cursor: pointer;
    list-style: none;
    margin: 0px 0;
    padding: 3px 12px;
}

.knockout-autocomplete.menu li.selected {
    background-color: #eee;
}
